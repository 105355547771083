<template>
  <BaseHover v-model="hover">
    <div class="position-relative elevation-2 rounded-lg">
      <BaseImage
        :imageId="seminarVenueImageId"
        :aspect-ratio="1 / 1"
        :class="{ isMain }"
        :style="borderColor"
        class="rounded-lg"
      />
      <v-fade-transition>
        <SeminarVenueImageDelete
          v-if="hover"
          :seminarVenueImageId="seminarVenueImageId"
          @reload="$emit('reload')"
        />
      </v-fade-transition>
    </div>
  </BaseHover>
</template>

<script>
import SeminarVenueImageDelete from "./SeminarVenueImageDelete.vue";

export default {
  name: "SeminarVenueImage",

  components: {
    SeminarVenueImageDelete,
  },

  data() {
    return {
      hover: false,
    };
  },

  props: {
    seminarVenueImageId: {
      type: Number,
      required: true,
      default: null,
    },

    mainImageId: {
      type: [Number, undefined],
      required: true,
      default: null,
    },
  },

  computed: {
    isMain() {
      return this.seminarVenueImageId === this.mainImageId;
    },

    borderColor() {
      return {
        borderColor: this.$vuetify.theme.themes.light.primary,
      };
    },
  },
};
</script>
+
<style lang="scss" scoped>
.isMain {
  border: 4px solid;
}
</style>
