<template>
  <v-row>
    <v-col cols="12">
      <BaseHeadline size="4">
        {{ $t("SEMINAR_MANAGEMENT.SEMINAR_VENUES.IMAGES.UPLOAD.TITLE") }}
      </BaseHeadline>
    </v-col>

    <v-col cols="12">
      <BaseCheckbox
        label="SEMINAR_MANAGEMENT.SEMINAR_VENUES.IMAGES.UPLOAD.MAIN_IMAGE"
        v-model="isMain"
      />
    </v-col>

    <v-col cols="12" md="6" class="mb-n3">
      <SingleImageUpload :target="target" @reload="reload" />
    </v-col>
  </v-row>
</template>

<script>
import SingleImageUpload from "@components/FormElements/SingleImageUpload.vue";

export default {
  name: "SeminarVenueImageUpload",

  data() {
    return {
      isMain: false,
      timeOut: null,
    };
  },

  components: {
    SingleImageUpload,
  },

  computed: {
    seminarVenueId() {
      return parseInt(this.$route.params.seminarVenueId, 10);
    },

    target() {
      if (this.isMain)
        return {
          seminarVenueId: this.seminarVenueId,
          callback: this.$axios.seminarManagement.storeSeminarVenueMainImage,
        };

      return {
        seminarVenueId: this.seminarVenueId,
        callback: this.$axios.seminarManagement.storeSeminarVenueImage,
      };
    },
  },

  methods: {
    reload() {
      this.timeOut = setTimeout(() => {
        this.$emit("reload");
      }, 500);
    },
  },

  beforeDestroy() {
    clearTimeout(this.timeOut);
  },
};
</script>
