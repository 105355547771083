<template>
  <v-row>
    <v-col cols="12">
      <BaseHeadline size="4">
        {{ $t("SEMINAR_MANAGEMENT.SEMINAR_VENUES.FORM.TITLES.VISIBILITY") }}
      </BaseHeadline>
    </v-col>

    <v-col cols="12" class="mb-n3">
      <BaseSwitch
        v-model="formValue.isPublished"
        label="SEMINAR_MANAGEMENT.SEMINAR_VENUES.FORM.LABELS.IS_PUBLISHED"
      />
    </v-col>

    <v-col cols="12">
      <BaseHeadline size="4">
        {{ $t("SEMINAR_MANAGEMENT.SEMINAR_VENUES.FORM.TITLES.SEMINAR_EQUIPMENT") }}
      </BaseHeadline>
    </v-col>

    <v-col cols="12" md="6" class="mb-n3">
      <SeminarEquipmentSelect v-model="formValue.seminarEquipment" />
    </v-col>

    <v-col cols="12" md="6" class="mb-n3">
      <SeminarVenueRoomEquipmentSelect v-model="formValue.seminarVenueRoomEquipment" />
    </v-col>

    <v-col cols="12">
      <BaseHeadline size="4">
        {{ $t("SEMINAR_MANAGEMENT.SEMINAR_VENUES.FORM.TITLES.INFORMATION") }}
      </BaseHeadline>
    </v-col>

    <v-col cols="12" md="6" class="mb-n3">
      <SeminarVenueTypeSelect v-model="formValue.seminarVenueTypeId" />
    </v-col>

    <v-col cols="12" md="6" class="mb-n3">
      <BaseTextField
        v-model="formValue.vrLink"
        label="SEMINAR_MANAGEMENT.SEMINAR_VENUES.FORM.LABELS.VR_LINK"
      />
    </v-col>

    <v-col cols="12" class="mb-n3">
      <BaseTextArea
        v-model="formValue.teaser"
        label="SEMINAR_MANAGEMENT.SEMINAR_VENUES.FORM.LABELS.TEASER"
        max="100"
      />
    </v-col>

    <v-col cols="12" class="mb-n3">
      <BaseTextArea
        v-model="formValue.description"
        label="SEMINAR_MANAGEMENT.SEMINAR_VENUES.FORM.LABELS.DESCRIPTION"
        max="-1"
      />
    </v-col>

    <v-col cols="12" class="mb-n3">
      <BaseTextArea
        v-model="formValue.cateringInfo"
        label="SEMINAR_MANAGEMENT.SEMINAR_VENUES.FORM.LABELS.CATERING_INFO"
        max="100"
      />
    </v-col>

    <v-col cols="12">
      <v-row>
        <v-spacer />
        <v-col cols="auto">
          <BaseButton outlined color="error" @click="$emit('cancel')">
            {{ $t("SEMINAR_MANAGEMENT.SEMINAR_VENUES.UPDATE.CANCEL") }}
          </BaseButton>
        </v-col>

        <v-col cols="auto">
          <BaseButton @click="$emit('save')">
            {{ $t("SEMINAR_MANAGEMENT.SEMINAR_VENUES.UPDATE.SAVE") }}
          </BaseButton>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import SeminarVenueTypeSelect from "@components/FormElements/SeminarVenueTypeSelect.vue";
import SeminarEquipmentSelect from "@components/FormElements/SeminarEquipmentSelect.vue";
import SeminarVenueRoomEquipmentSelect from "@components/FormElements/SeminarVenueRoomEquipmentSelect.vue";

export default {
  name: "SeminarVenueForm",

  components: {
    SeminarVenueTypeSelect,
    SeminarEquipmentSelect,
    SeminarVenueRoomEquipmentSelect,
  },

  props: {
    value: {
      type: Object,
      required: true,
      default: () => ({
        seminarVenueTypeId: null,
        name2: null,
        name3: null,
        teaser: null,
        description: null,
        cateringInfo: null,
        vrLink: null,
        seminarEquipment: null,
        seminarVenueRoomEquipment: null,
        imageData: null,
        mainImageId: null,
        isPublished: false,
      }),
    },
  },

  computed: {
    formValue: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
};
</script>
