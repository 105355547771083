<template>
  <div :style="styles">
    <v-tooltip bottom>
      <template #activator="{ on, attrs }">
        <v-btn icon large @click="deleteSeminarVenueImage" v-bind="attrs" v-on="on">
          <v-icon large color="white"> far fa-trash </v-icon>
        </v-btn>
      </template>

      <span>{{ $t("SEMINAR_MANAGEMENT.SEMINAR_VENUES.IMAGES.DELETE.TOOLTIP") }}</span>
    </v-tooltip>
  </div>
</template>

<script>
export default {
  name: "SeminarVenueImageDelete",

  props: {
    seminarVenueImageId: {
      type: Number,
      required: true,
      default: null,
    },
  },

  computed: {
    styles() {
      return {
        height: "100%",
        width: "100%",
        position: "absolute",
        top: 0,
        left: 0,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: this.$vuetify.theme.themes.light.primary,
        opacity: 0.9,
      };
    },

    seminarVenueId() {
      return parseInt(this.$route.params.seminarVenueId, 10);
    },
  },

  methods: {
    async deleteSeminarVenueImage() {
      try {
        await this.$axios.seminarManagement.deleteSeminarVenueImage({
          imageId: this.seminarVenueImageId,
          seminarVenueId: this.seminarVenueId,
        });

        this.$emit("reload");

        return Promise.resolve();
      } catch (err) {
        return Promise.reject(err);
      }
    },
  },
};
</script>
