<template>
  <v-row v-if="showGallery">
    <v-col cols="12" v-if="!loading">
      <v-row>
        <v-col cols="12">
          <BaseHeadline size="3">
            {{ $t("SEMINAR_MANAGEMENT.SEMINAR_VENUES.IMAGES.GALLERY.TITLE") }}
          </BaseHeadline>
        </v-col>

        <v-col v-for="image of images" :key="image" cols="3">
          <SeminarVenueImage
            :seminarVenueImageId="image"
            @reload="$emit('reload')"
            :mainImageId="mainImageId"
          />
        </v-col>
      </v-row>
    </v-col>
    <v-col cols="12" v-else>
      <v-row>
        <v-col cols="12">
          <v-progress-circular indeterminate color="primary" />
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import SeminarVenueImage from "./partials/SeminarVenueImage.vue";
import { isEmpty } from "lodash";

export default {
  name: "SeminarVenueImageGallery",

  components: {
    SeminarVenueImage,
  },

  props: {
    seminarVenueImages: {
      type: Array,
      required: true,
      default: () => [],
    },

    mainImageId: {
      type: [Number, undefined],
      required: false,
      default: null,
    },

    loading: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  computed: {
    images() {
      return this.seminarVenueImages.map(({ id }) => id);
    },

    showGallery() {
      return !isEmpty(this.images);
    },
  },
};
</script>
