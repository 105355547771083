<template>
  <ModuleSubPage :loading="isLoading">
    <template #title> {{ seminarVenue.name1 }} </template>

    <BaseHasPermission :permissionNames="['seminar_venue.update']">
      <v-row>
        <v-col cols="12">
          <BaseBackButton />
        </v-col>

        <v-col cols="12">
          <v-form v-on:submit.prevent ref="form" v-model="formValidation" lazy-validation>
            <SeminarVenueForm
              v-model="seminarVenue"
              @deleteImage="deleteImage = true"
              @save="save"
              @cancel="cancel"
            />
          </v-form>
        </v-col>

        <v-col cols="12" v-if="seminarVenue">
          <SeminarVenueImageGallery
            :seminarVenueImages="images"
            @reload="retrieveSeminarVenueImages"
            :loading="imagesAreLoading"
            :mainImageId="seminarVenueMainImageId"
          />
        </v-col>

        <v-col cols="12">
          <SeminarVenueImageUpload @reload="retrieveSeminarVenueImages" />
        </v-col>
      </v-row>
    </BaseHasPermission>
  </ModuleSubPage>
</template>

<script>
import SeminarVenueForm from "@views/Restricted/SeminarManagement/SeminarVenues/partials/SeminarVenueForm.vue";
import formValidation from "@mixins/formValidation";
import ModuleSubPage from "@components/Layout/ModuleSubPage.vue";
import SeminarVenueImageUpload from "../partials/SeminarVenueImageUpload.vue";
import SeminarVenueImageGallery from "../partials/SeminarVenueImageGallery";
import { mapActions } from "vuex";

export default {
  name: "UpdateSeminarVenue",

  mixins: [formValidation],

  components: {
    SeminarVenueForm,
    ModuleSubPage,
    SeminarVenueImageUpload,

    SeminarVenueImageGallery,
  },

  data() {
    return {
      seminarVenue: this.initSeminarVenue(),
      isLoading: true,
      deleteImage: false,
      images: [],
      imagesAreLoading: true,
    };
  },

  computed: {
    seminarVenueId() {
      return parseInt(this.$route.params.seminarVenueId, 10);
    },

    updateParams() {
      return {
        seminarVenueId: this.seminarVenueId,
        ...this.seminarVenue,
      };
    },

    syncSeminarEquipmentParams() {
      return {
        seminarVenueId: this.seminarVenueId,
        seminarEquipmentIds: this.seminarVenue.seminarEquipment,
      };
    },

    syncSeminarVenueRoomEquipmentParams() {
      return {
        seminarVenueId: this.seminarVenueId,
        seminarEquipmentIds: this.seminarVenue.seminarVenueRoomEquipment,
      };
    },

    seminarVenueMainImageId() {
      if (!this.seminarVenue.mainImage) return null;

      return this.seminarVenue.mainImage.id;
    },
  },

  methods: {
    ...mapActions("notifications", ["notify"]),

    cancel() {
      this.$router.go(-1);
    },

    async updateSeminarVenue() {
      try {
        await this.$axios.seminarManagement.updateSeminarVenue(this.updateParams);

        return Promise.resolve();
      } catch (err) {
        return Promise.reject(err);
      }
    },

    async syncSeminarEquipment() {
      try {
        await this.$axios.seminarManagement.syncSeminarEquipment(this.syncSeminarEquipmentParams);

        return Promise.resolve();
      } catch (err) {
        return Promise.reject(err);
      }
    },

    async syncSeminarVenueRoomEquipment() {
      try {
        await this.$axios.seminarManagement.syncSeminarVenueRoomEquipment(
          this.syncSeminarVenueRoomEquipmentParams
        );

        return Promise.resolve();
      } catch (err) {
        return Promise.reject(err);
      }
    },

    save() {
      this.validate();

      this.$nextTick(async () => {
        if (this.formValidation) {
          this.isLoading = true;
          try {
            await this.updateSeminarVenue();
            await this.syncSeminarEquipment();
            await this.syncSeminarVenueRoomEquipment();

            this.notify({
              type: "success",
              message: "SEMINAR_MANAGEMENT.SEMINAR_VENUES.UPDATE.SUCCESS",
            });

            this.$emit("reload");
          } catch (err) {
            console.error(err);
            this.setValidationErrors(err);
          } finally {
            this.isLoading = false;
          }
        }
      });
    },

    async showSeminarVenue() {
      this.isLoading = true;
      try {
        const { data } = await this.$axios.seminarManagement.showSeminarVenue({
          seminarVenueId: this.seminarVenueId,
        });

        this.seminarVenue = {
          ...data.data,
          seminarEquipment: data.data.seminarEquipment.map(({ id }) => id),
          seminarVenueRoomEquipment: data.data.seminarVenueRoomEquipment.map(({ id }) => id),
        };
      } catch (err) {
        console.error(err);
      } finally {
        this.isLoading = false;
      }
    },

    async retrieveSeminarVenueImages() {
      this.imagesAreLoading = true;

      try {
        const { data } = await this.$axios.seminarManagement.listSeminarVenueImages({
          page: 1,
          perPage: 0,
          seminarVenueId: this.seminarVenueId,
        });
        this.images = data.data;
      } catch (err) {
        console.error(err);
      } finally {
        this.imagesAreLoading = false;
      }
    },

    async initData() {
      await this.showSeminarVenue();
      await this.retrieveSeminarVenueImages();

      return Promise.resolve();
    },

    initSeminarVenue() {
      return {
        seminarVenueTypeId: null,
        name2: null,
        name3: null,
        teaser: null,
        description: null,
        cateringInfo: null,
        vrLink: null,
        seminarEquipment: null,
        seminarVenueRoomEquipment: null,
        imageData: null,
        isPublished: false,
      };
    },
  },

  async created() {
    await this.initData();
  },
};
</script>
