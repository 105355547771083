<template>
  <v-row class="mb-4">
    <v-col cols="12">
      <v-fade-transition>
        <v-row no-gutters justify="center">
          <v-col cols="12" class="mb-4" v-if="imageValue && !hidePreview">
            <v-img :src="imagePreview" />
          </v-col>

          <v-col cols="12" align-self="center">
            <v-form ref="form" v-on:submit.prevent v-model="formValidation" lazy-validation>
              <BaseFileInput
                v-model="imageValue"
                label="FORM_ELEMENTS.IMAGE.LABEL"
                accept="image/*"
                :rules="this.maxFileSize(2048)"
                :loading="isLoading"
              />
            </v-form>
          </v-col>

          <v-col cols="12" align-self="center">
            <BaseButton
              :disabled="buttonIsDisabled"
              :loading="isLoading"
              @click="uploadImage"
              width="100%"
            >
              {{ $t("FORM_ELEMENTS.IMAGE.UPLOAD") }}
            </BaseButton>
          </v-col>
        </v-row>
      </v-fade-transition>
    </v-col>
  </v-row>
</template>

<script>
import formValidation from "@/mixins/formValidation";
import defaultImage from "@/assets/img/default-image-upload.jpg";

export default {
  name: "SingleImageUpload",

  mixins: [formValidation],

  data() {
    return {
      isLoading: false,
      imageValue: null,
    };
  },

  props: {
    target: {
      type: Object,
      required: true,
      default: () => ({}),
    },

    hidePreview: {
      type: Boolean,
      default: false,
      required: false,
    },
  },

  computed: {
    buttonIsDisabled() {
      return !this.imageValue || this.isLoading;
    },

    imagePreview() {
      if (this.imageValue) return URL.createObjectURL(new Blob([this.imageValue]));

      return defaultImage;
    },

    targetKey() {
      return Object.keys(this.target)[0];
    },
  },

  methods: {
    uploadImage() {
      this.validate();

      this.$nextTick(async () => {
        if (this.formValidation) {
          this.isLoading = true;

          try {
            this.target.callback({
              image: this.imageValue,
              [this.targetKey]: this.target[this.targetKey],
            });

            this.resetForm();
            this.$emit("reload");

            return Promise.resolve();
          } catch (err) {
            return Promise.reject(err);
          } finally {
            this.isLoading = false;
          }
        }
      });
    },

    resetForm() {
      this.resetValidation();
      this.reset();
      this.imageValue = null;
    },
  },
};
</script>
